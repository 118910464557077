import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
// import { EventList } from '/src/components/events'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const RangePage = () => {
  return (
    <Layout hero={<HeroImage title="Range" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                Orrcon Steel has two Australian manufacturing sites - A structural tubular mill in Brisbane and a precision tubing mill in Adelaide.
              </p>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col sm={6}>
              <div className="title-container mb-3">
                <h4 className="title">
                  Structural Steel
                </h4>
              </div>
              <ul className="mb-4">
                <li>
                  <p>Orrcon Steel offers a wide range of Electric Resistance Welding (ERW) Carbon Steel Structural Tubulars.</p>
                </li>
                <li>
                  <p>Our Structural Tubular Products are a significant part of our production and are used across a wide range of structural applications from building structures to major sporting arenas, major mining and industry infrastructure and for aesthetic structural use such as airports and shipping terminals.</p>
                </li>
                <li>
                  <p>For further information, visit Structural Steel Pipe and Tube</p>
                </li>
              </ul>
              <div className="title-container mb-3">
                <h4 className="title">
                  Precision Tube 
                </h4>
              </div>
              <ul>
                <li>
                  <p>Orrcon Steel manufactures precision tube at its precision tubing mill in Adelaide. This mill produces square, rectangular, round and oval tube and can also manufacture special shapes subject to enquiry. All precision products are manufactured to comply with AS/NZS 1450.</p>
                </li>
                <li>
                  <p>For further information, visit Precision Pipe and Tube</p>
                </li>
              </ul>
            </Col>
            <Col sm={6}>
              <div className="title-container mb-3">
                <h4 className="title">
                  Oil, Gas, Water and Slurry Pipelines
                </h4>
              </div>
              <ul className="mb-4">
                <li>
                  <p>Orrcon Steel is able to procure oil, gas, water and slurry pipelines at competitive prices. Our global network of suppliers allows us to provide a large range of pipeline procurement solutions which are delivered on time, on budget and to required specifications.</p>
                </li>
                <li>
                  <p>For further information, visit our product range</p>
                </li>
              </ul>
              <div className="title-container mb-3">
                <h4 className="title">
                  Hot Rolled Structural Steel
                </h4>
              </div>
              <ul>
                <li>
                  <p>Orrcon Steel offers a range of hot rolled structural products including: Beams, columns, channels, angles and merchant bar (including flats, angles & rounds) as well as a broad range of plate from 3mm up to 200mm.</p>
                </li>
                <li>
                  <p>For further information, visit Hot Rolled Structural</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">
              We support local communities by providing
            </h1>
          </div>
          <LinkedList items={[
            {
              content: <LinkedListItem 
                title="DISTRIBUTION"
                content="" />,
              path: '/community/distribution'
            },
            {
              content: <LinkedListItem 
                title="RANGE"
                content="" />,
              path: '/community/range'
            },
            {
              content: <LinkedListItem 
                title="MANUFACTURING"
                content="" />,
              path: '/community/manufacturing'
            }
          ]} />
        </Col>
      </Row>
    </Layout>
  )
}

export default RangePage

export const Head = () => <title>Range | Orrcon Steel</title>
